import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import MuseUI from 'muse-ui';
import 'muse-ui/dist/muse-ui.css';
import Toast from 'muse-ui-toast';
import i18n from "./i18n/index.js"


Vue.config.productionTip = false

Vue.use(MuseUI);
Vue.use(Toast);

Vue.prototype.$axios = axios


new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
