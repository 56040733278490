import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)
// 准备翻译的语言环境信息
const messages = {
  en: require('./language/en.json'),
  zh: require('./language/zh.json'),
  tw: require('./language/tw.json'),
  vi: require('./language/vi.json')
}

// 通过选项创建 VueI18n 实例
const i18n = new VueI18n({
  locale: 'en', // 设置地区
  messages // 设置地区信息
});

export default i18n;
